import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

/**
 *
 * Use this plugin to access the editor instance outside of the
 * LexicalComposer. This can help with things like buttons or other
 * UI components that need to update or read EditorState but need to
 * be positioned outside the LexicalComposer in the React tree.
 */
export default function EditorRefPlugin({ editorRef }) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (typeof editorRef === 'function') {
      editorRef(editor)
    } else if (typeof editorRef === 'object') {
      editorRef.current = editor
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  return null
}
